import React from "react";
import { useEffect } from "react";

function ErrorAlert(customError,setCustomError) {
  useEffect(() => {
    setTimeout(() => {
      customError.setCustomError(null);
    }, 3000);


  }, [customError]);

  const errorShow = () => {
    if (customError.customError != null) {
      return (
        <div className="alert alert-error shadow-lg">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="stroke-current flex-shrink-0 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            {/* error to string */}
            <p className="ml-3">{customError.customError}</p>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="fixed z-30 bottom-0 left-0" id="alertError">
      {errorShow()}
    </div>
  );
}

export default ErrorAlert;
